function beforeSend(xhr) {
    // var token = genSecretToken();
    // xhr.setRequestHeader('clientToken', token);
};
function genSecretToken(){
    var timeDelta = $.cookie('timeDelta');
    timeDelta = parseFloat(timeDelta);
    var t = new Date().getTime() + timeDelta;
    var date = new Date();
    date.setTime(t);
    date.setSeconds(0);
    date.setMilliseconds(0);
    var sessionId = $.cookie('HTSESSIONID');
    var token = $.md5(date.getTime() +":"+ sessionId);
    return token
}
function updateUrlAppendToken(url) {
    var retrUrl = url;
    var tokenStr = "_htrandom="+genSecretToken();
    if(url.indexOf('?') === -1){
        retrUrl += "?" +tokenStr;
    }else{
        retrUrl +="&"+tokenStr;
    }
    return retrUrl;
}